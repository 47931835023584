import React, {
    ReactElement, useCallback, useRef, useState
} from "react";
import {Link} from "react-router-dom";
import {useTranslation, withTranslation} from "react-i18next";
import cx from "classnames";
import animatedLogo from "assets/images/logoAnimated.gif";
import getHotelAddress from "utils/getHotelAddress";
import useBrowserTitle from "utils/hooks/useBrowserTitle";
import {
    HotelFeatureGroupPropTypes,
    HotelFeaturePropTypes,
    HotelPropTypes,
    HotelSearchCriteriaPropTypes,
    HotelSearchResultsFiltersPropTypes,
    RoomOfferPropTypes
} from "proptypes/PropTypeObjects";
import FavoriteHotel from "components/common/FavoriteHotel";
import RoomsContainer from "components/common/Rooms";
import HotelCustomerReviews from "components/common/HotelCustomerReviews/HotelCustomerReviews";
import Stars from "components/common/Stars";
import FeatureIcon from "components/common/Feature/FeatureIcon";
// import CollapsibleBox from "components/base/CollapsibleBox/CollapsibleBox";
import CollapsibleBox from "@hotelston_web_frontend_components/components/base/CollapsibleBox/CollapsibleBox";
import {ReactComponent as LocationIcon} from "assets/icons/location.svg";
import HotelImageViewer from "./ImageViewer/HotelImageViewer";
import HotelOffersIndicator from "./HotelOffersIndicator/HotelOffersIndicator";
import MapWrapper from "./MapContainer";
import FeaturesSearch from "./FeaturesSearch/FeaturesSearch";
import Location from "./Location/Location";
import SearchBox from "./SearchBox";
import styles from "./Hotel.module.scss";

type Props = {
    data: {
        zoom: number,
        center: google.maps.LatLng
        fullWidthMap: boolean,
        error?: string,
        hotel: HotelPropTypes,
        roomOffers?: RoomOfferPropTypes[][],
        searchCriteriaChanged: boolean,
        backup: {
            nationalityInput?: string,
            formData?: HotelSearchCriteriaPropTypes,
            activeFilters?: string[],
            filters?: any
        }

        formData: HotelSearchCriteriaPropTypes;
        filters: HotelSearchResultsFiltersPropTypes;
        activeFilters?: string[];
        searchError: any;
        isSearching: boolean;
        isActivePinnedOffers: boolean;
        mapTypeId: any;
    }
    handleSearchCriteriaChanged: () => void;
    handleFullWidthMap: () => void;
    changeCenter: (center: google.maps.LatLng) => void;
    changeZoom: (zoom: number) => void;
    discardChanges: () => void;
    changeMapTypeId: (mapTypeId: ("roadmap" | "hybrid")) => void;
};

const Hotel = (props: Props): ReactElement => {
    const {
        data: {
            fullWidthMap,
            zoom,
            center,
            mapTypeId,
            hotel: {
                address,
                coordinates,
                description,
                distances,
                featureGroups,
                safetyFeatureGroups,
                customerRatings,
                tripAdvisorRating,
                reviewRating,
                id,
                //images,
                name,
                phone,
                starRating
            },
            roomOffers,
            searchCriteriaChanged,
            searchError,
            isSearching,
            isActivePinnedOffers
        },
        handleFullWidthMap,
        changeZoom,
        changeCenter,
        discardChanges,
        handleSearchCriteriaChanged,
        changeMapTypeId
    } = props;

    const [editSearchCriteria, setEditSearchCriteria] = useState<boolean>(false);
    const [featuresSearchInput, setFeaturesSearchInput] = useState<string>("");
    const [safetyFeaturesSearchInput, setSafetyFeaturesSearchInput] = useState<string>("");

    const roomsContainerRef = useRef<HTMLFormElement>(null);

    const {
        data: {
            hotel
        }
    } = props;

    const {t} = useTranslation();
    useBrowserTitle({title: hotel?.name});

    const createMarkup = useCallback((html: any) => ({
        __html: html.toString()
    }), []);

    const toggleEditSearchCriteria = useCallback(() => setEditSearchCriteria(!editSearchCriteria), [editSearchCriteria]);

    return (
        <div className={styles.Root}>
            <div className={cx(styles.Content, fullWidthMap && styles.ContentFullWidthMap)}>
                <div className={styles.InfoContainer}>
                    <div className={styles.ContentBlock}>
                        <HotelOffersIndicator
                            isSearching={isSearching}
                            elementToObserve={roomsContainerRef.current}
                            searchError={!!searchError || !roomOffers || roomOffers.length === 0}
                        />

                        <div className={styles.TopInfoBlock}>
                            <div className={styles.TopInfoTitleIcons}>
                                <h1>{name}</h1>

                                <div className={styles.TopInfoIcons}>
                                    <FavoriteHotel id={id} className={styles.TopInfoHeartIcon} />
                                </div>
                            </div>

                            <div className={styles.TopInfoContainer}>
                                <Stars starRating={starRating} />

                                {/*<div className="trust d-inline-block">*/}
                                <HotelCustomerReviews
                                    reviewRating={reviewRating}
                                    customerRatings={customerRatings}
                                    tripAdvisorRating={tripAdvisorRating}
                                    className={styles.HotelCustomerReviews}
                                />
                                {/*<span className="mr-3">5/5 Trust you</span>*/}
                                {/*<span>4/5 Trip advisor</span>*/}
                                {/*</div>*/}
                            </div>

                            <div className={styles.TopInfoAddress}>
                                <LocationIcon />
                                {getHotelAddress(address)}
                            </div>
                        </div>

                        <HotelImageViewer
                            zoom={zoom}
                            hotel={hotel}
                        />

                        {description && (
                            <div className={styles.MiddleInfo}>
                                <div
                                    dangerouslySetInnerHTML={createMarkup(description)}
                                    className={styles.MiddleInfoText}
                                />
                            </div>
                        )}

                        <div className={styles.BottomInfo}>
                            <CollapsibleBox
                                className={cx(styles.BottomInfoBlock, styles.HealthSafetyBlock)}
                                title={t("h_h_h_features")}
                            >
                                <div>
                                    <div className={styles.BottomInfoTopRow}>
                                        <FeaturesSearch
                                            handleInput={(event) => setFeaturesSearchInput(event.target.value)}
                                            input={featuresSearchInput} inputName="featuresSearchInput"
                                        />
                                    </div>

                                    {featureGroups && featureGroups.length > 0 && (
                                        <dl>
                                            {featureGroups.filter(({hotelFeatures, name}: HotelFeatureGroupPropTypes) => name.toLowerCase()
                                                .includes(featuresSearchInput.toLowerCase()) || hotelFeatures.filter(({name}: HotelFeaturePropTypes) => name.toLowerCase()
                                                .includes(featuresSearchInput.toLowerCase())).length > 0)
                                                .map((featureGroup: HotelFeatureGroupPropTypes) => (
                                                    <div key={featureGroup.id}>
                                                        <dt>
                                                            <FeatureIcon
                                                                featureGroupId={featureGroup.id} width="28px"
                                                                height="14px" className="mr-2"
                                                            />
                                                            {featureGroup.name}
                                                        </dt>
                                                        {featureGroup.hotelFeatures.filter(({name}: HotelFeaturePropTypes) => featureGroup.name.toLowerCase()
                                                            .includes(featuresSearchInput.toLowerCase()) || name.toLowerCase()
                                                            .includes(featuresSearchInput.toLowerCase()))
                                                            .map(({name}: HotelFeaturePropTypes, i: number) => (
                                                                <dd className={styles.FeatureItem} key={i}>
                                                                    <p>{name}</p>
                                                                </dd>
                                                            ))}
                                                    </div>
                                                ))}
                                        </dl>
                                    )}
                                </div>
                            </CollapsibleBox>

                            {safetyFeatureGroups && safetyFeatureGroups.length > 0 && (
                                <CollapsibleBox
                                    className={cx(styles.BottomInfoBlock, styles.HealthSafetyBlock)}
                                    title={t("h_h_h_health_and_safety")}
                                >
                                    <div>
                                        <div className={styles.BottomInfoTopRow}>
                                            {/*<h3 className={styles.BottomInfoTopRowHeading}>{t("h_h_h_health_and_safety")}</h3>*/}

                                            <FeaturesSearch
                                                handleInput={(event) => setSafetyFeaturesSearchInput(event.target.value)}
                                                input={safetyFeaturesSearchInput} inputName="safetyFeaturesSearchInput"
                                            />
                                        </div>

                                        <dl>
                                            {safetyFeatureGroups && safetyFeatureGroups.length > 0 && (
                                                <>
                                                    {safetyFeatureGroups.filter(({
                                                        hotelFeatures,
                                                        name
                                                    }) => name.toLowerCase()
                                                        .includes(safetyFeaturesSearchInput.toLowerCase()) || hotelFeatures.filter(({name}) => name.toLowerCase()
                                                        .includes(safetyFeaturesSearchInput.toLowerCase())).length > 0)
                                                        .map((featureGroup) => (
                                                            <div key={featureGroup.id}>
                                                                <dt>
                                                                    <FeatureIcon
                                                                        featureGroupId={featureGroup.id} width="28px"
                                                                        height="14px" className="mr-2"
                                                                    />
                                                                    {featureGroup.name}
                                                                </dt>

                                                                {featureGroup.hotelFeatures.filter(({name}: HotelFeaturePropTypes) => featureGroup.name.toLowerCase()
                                                                    .includes(safetyFeaturesSearchInput.toLowerCase()) || name.toLowerCase()
                                                                    .includes(safetyFeaturesSearchInput.toLowerCase()))
                                                                    .map(({name}: HotelFeaturePropTypes, i: number) => (
                                                                        <dd className={styles.FeatureItem} key={i}>
                                                                            <p>{name}</p>
                                                                        </dd>
                                                                    ))}
                                                            </div>
                                                        ))}
                                                </>
                                            )}
                                        </dl>

                                        <div>{t("h_h_h_health_and_safety_footer")}</div>
                                    </div>
                                </CollapsibleBox>
                            )}

                            {distances && distances.length > 0 && (
                                <div className={styles.BottomInfoBlock}>
                                    <div className={cx(styles.BottomInfoTopRowHeading, styles.LocationColumnsBlock)}>
                                        <h4>{t("h_h_h_location")}</h4>

                                        <Location distances={distances} />
                                    </div>
                                </div>
                            )}

                            <div className={styles.BottomInfoBlock}>
                                <div className={styles.BottomInfoTopRow}>
                                    <h2 className={styles.BottomInfoTopRowHeading}>
                                        {!editSearchCriteria ? t("h_h_h_availability") : t("h_h_h_edit_search_criteria")}
                                    </h2>

                                    <span onClick={toggleEditSearchCriteria} className={styles.BottomInfoAction}>{t("h_h_h_edit_search_criteria")}</span>
                                </div>

                                {id && (
                                    <SearchBox
                                        editSearchCriteria={editSearchCriteria}
                                        toggleEditSearchCriteria={toggleEditSearchCriteria}
                                        discardChanges={discardChanges}
                                        handleSearchCriteriaChanged={handleSearchCriteriaChanged}
                                        searchCriteriaChanged={searchCriteriaChanged}
                                        currentHotelId={id}
                                    />
                                )}
                            </div>
                        </div>

                        {!isSearching && !searchError && roomOffers && id && (
                            <>
                                <RoomsContainer
                                    formRef={roomsContainerRef}
                                    className={styles.HotelRooms}
                                    hotelId={id}
                                    minShowResults={3}
                                    initialShowAllRooms={true}
                                    fullWidthMap={fullWidthMap}
                                    roomOffers={roomOffers}
                                    isActivePinnedOffers={isActivePinnedOffers}
                                />
                                <div className="space-after" />
                            </>
                        )}

                        {isSearching && (
                            // <Spinner
                            //     size="50px"
                            //     style={{
                            //         width: "100%",
                            //         height: "250px"
                            //     }}
                            // />
                            <div className={styles.LoaderContainer}>
                                <img src={animatedLogo} alt="Animated logo" />

                                <span>{t("h_h_h_loading_room_offers")}</span>
                            </div>
                        )}

                        {!isSearching && (searchError || !roomOffers) && (
                            <div className={styles.ErrorBlock} id="HotelErrorBlock">
                                <h4 className={styles.ErrorHeading}>{t("h_h_h_no_results_found")}</h4>
                                <div className={styles.ErrorContent}>{t("h_h_h_hotel_search_nothing_found_notice")}</div>
                                <Link to="/hotels">{t("h_h_h_go_to_search_page")}</Link>
                            </div>
                        )}
                    </div>
                </div>

                <div className={styles.MapContainer}>
                    <MapWrapper
                        handleFullWidthMap={handleFullWidthMap}
                        changeZoom={changeZoom}
                        changeCenter={changeCenter}
                        changeMapTypeId={changeMapTypeId}
                        data={{
                            coordinates,
                            center,
                            zoom,
                            fullWidthMap,
                            address,
                            phone,
                            mapTypeId
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default withTranslation()(Hotel);
