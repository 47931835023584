import React, {ReactElement, useMemo} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import styles from "./PaymentSummary.module.scss";
import TransferFlightInfoBox from "../../../components/common/TransferFlightInfoBox";
import TransferPassengerBox from "../../../components/common/TransferPassengerBox";
import TransferRangeBox from "../../../components/common/TransferRangeBox";
import Edit from "../../../components/common/Edit";
import Pricing from "../../../components/common/Pricing";
import {TransferDetailsPropTypes} from "proptypes/PropTypeObjects";
import SpecificLocationBox from "components/common/SpecificLocationBox";
import ContactPersonBox from "components/common/ContactPersonBox";
import PickupTimeSMSBox from "components/common/PickupTimeSMSBox";
import BillingDetailsBox from "components/common/BillingDetailsBox";
import MemoBox from "components/common/MemoBox";
import router from "views/router";
import createLuxonDate from "utils/date/createLuxonDate"
import {useAppSelector} from "redux/hooks"
import usePaymentOptions from "components/base/Payment/PaymentType/usePaymentOptions"
import useDefaultFeatureEnabled from "../../../utils/hooks/useDefaultFeatureEnabled";

type DestinationLabels = {
    bigTextTo?: string;
    smallTextTo?: string;

    bigTextFrom?: string;
    smallTextFrom?: string;
};

export default function BookingSummary(): ReactElement {
    const {t} = useTranslation();

    const {
        featureEnabled: newTransferSearch,
        featuresLoaded
    } = useDefaultFeatureEnabled("web2NewTransferSearch");

    const bookingInfo = useAppSelector((state) => state.transferBooking.data?.bookingInfo);
    const transferBookingInfo = useAppSelector((state) => state.transferBooking.data?.transferBookingInfo);
    const transferAvailabilityCriteria = useAppSelector((state) => state.transferBooking.data?.transferAvailabilityCriteria);
    const transfer = useAppSelector((state) => state.transferBooking.transfer);
    const currentOrigin = useAppSelector((state) => state.transferSearch.currentOrigin);
    const currentDestination = useAppSelector((state) => state.transferSearch.currentDestination);

    const invoiceRecipient = useAppSelector((state) => state.payment.invoiceRecipient);
    const customInvoice = useAppSelector((state) => state.payment.customInvoice);

    const {
        paymentOptions,
        discount,
        voucherSum
    } = usePaymentOptions();

    const {
        arrivalAirlineName,
        arrivalAirportFrom,
        arrivalAirportTo,
        arrivalFlightNumber,
        departureAirlineName,
        departureAirportFrom,
        departureAirportTo,
        departureFlightNumber,
        leadPassenger,
        specificLocationAddress,
        specificLocationName,
        smsOrdered,
        smsReminderLocale,
        smsReminderNumber
    } = transferBookingInfo;

    const {
        adults = 1,
        children = [],
        arrivalTime = createLuxonDate().toISO(),
        departureTime = createLuxonDate().toISO(),
        arrivalTransfer = true,
        departureTransfer = false
    } = transferAvailabilityCriteria;

    const {
        smallTextFrom,
        smallTextTo,
        bigTextFrom,
        bigTextTo
    } = useMemo<DestinationLabels>(() => {
        if (!featuresLoaded) {
            return {};
        }

        let ret: DestinationLabels;
        if (newTransferSearch) {
            if (arrivalTransfer && !departureTransfer) {
                ret = {
                    smallTextFrom: currentOrigin?.countryName,
                    smallTextTo: currentDestination?.countryName,
                    bigTextFrom: currentOrigin?.name,
                    bigTextTo: currentDestination?.name
                };
            } else if (!arrivalTransfer && departureTransfer) {
                ret = {
                    smallTextFrom: currentDestination?.countryName,
                    smallTextTo: currentOrigin?.countryName,
                    bigTextFrom: currentDestination?.name,
                    bigTextTo: currentOrigin.name
                };
            } else {
                ret = {
                    smallTextFrom: currentOrigin?.countryName,
                    smallTextTo: currentDestination?.countryName,
                    bigTextFrom: currentOrigin?.name,
                    bigTextTo: currentDestination?.name
                };
            }
        } else if (arrivalTransfer) {
            ret = {
                smallTextFrom: currentOrigin?.name,
                smallTextTo: currentDestination?.name,
                bigTextFrom: currentDestination?.countryName,
                bigTextTo: currentOrigin.countryName
            };
        } else {
            ret = {
                smallTextFrom: currentDestination?.name,
                smallTextTo: currentOrigin?.name,
                bigTextFrom: currentDestination?.countryName,
                bigTextTo: currentDestination?.countryName
            };
        }

        if (_.isEmpty(ret.bigTextFrom)) {
            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;

            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;
        } else if (_.isEmpty(ret.bigTextTo)) {
            ret.bigTextTo = ret.smallTextTo;
            ret.smallTextTo = undefined;

            ret.bigTextFrom = ret.smallTextFrom;
            ret.smallTextFrom = undefined;
        }

        return ret;
    }, [arrivalTransfer, currentDestination, currentOrigin, departureTransfer, featuresLoaded, newTransferSearch]);

    const arrivalDetails = useMemo<TransferDetailsPropTypes | undefined>(() => {
        const defaultReturn = arrivalTransfer ? {
            airportFrom: arrivalAirportFrom,
            airportTo: arrivalAirportTo,
            flightNumber: arrivalFlightNumber,
            airlineName: arrivalAirlineName
        } as TransferDetailsPropTypes : undefined;
        if (!newTransferSearch) {
            return defaultReturn;
        }

        if (departureTransfer && !arrivalTransfer) {
            return {
                airportFrom: departureAirportFrom,
                airportTo: departureAirportTo,
                flightNumber: departureFlightNumber,
                airlineName: departureAirlineName
            } as TransferDetailsPropTypes;
        }

        return defaultReturn;
    }, [arrivalAirlineName, arrivalAirportFrom, arrivalAirportTo, arrivalFlightNumber, arrivalTransfer, departureAirlineName, departureAirportFrom, departureAirportTo, departureFlightNumber, departureTransfer, newTransferSearch]);

    const departureDetails = useMemo<TransferDetailsPropTypes | undefined>(() => {
        const defaultReturn = departureTransfer ? {
            airportFrom: departureAirportFrom,
            airportTo: departureAirportTo,
            flightNumber: departureFlightNumber,
            airlineName: departureAirlineName
        } as TransferDetailsPropTypes : undefined;
        if (!newTransferSearch) {
            return defaultReturn;
        }

        if (departureTransfer && !arrivalTransfer) {
            return arrivalDetails;
        }

        return defaultReturn;
    }, [arrivalDetails, arrivalTransfer, departureAirlineName, departureAirportFrom, departureAirportTo, departureFlightNumber, departureTransfer, newTransferSearch]);

    if (!paymentOptions || !transfer || !bookingInfo) {
        return (
            <></>
        );
    }

    const {
        displaySalePrice = 0,
        paymentPending,
        salePrice = 0,
        creditLineConfirmationDate
    } = paymentOptions;

    const {transferName, pax = 1} = transfer;

    const {
        memo,
        contactInfo
    } = bookingInfo;

    const foundMessageLanguage = paymentOptions?.smsLocales && paymentOptions?.smsLocales.find(({lang}) => smsReminderLocale === lang);

    const arrivalFlightDate = createLuxonDate(arrivalTime);
    const departureFlightDate = createLuxonDate(departureTime);

    return (
        <div className={styles.root}>
            <h3 className={styles.title}>
                {t("t_p_ps_booking_summary")}
                <Edit
                    style={{
                        fontSize: "14px",
                        fontWeight: 600,
                        lineHeight: "22px"
                    }}
                    onClick={() => router.navigate("/transfers/booking")}
                    what={t("t_p_ps_edit_booking_button")}
                />
            </h3>

            <div className={styles.name}>{transferName}</div>

            <div className={styles.text}>
                <span>{t("t_p_ps_cancellation_rules")}:</span>

                {t("t_p_ps_warning_about_credit_line_cancellation", {
                    creditLineConfirmationDate
                })}
            </div>

            <TransferRangeBox
                smallTextFrom={smallTextFrom}
                smallTextTo={smallTextTo}
                bigTextFrom={bigTextFrom}
                bigTextTo={bigTextTo}
            />

            <TransferPassengerBox
                pax={pax}
                adultsCount={adults}
                childrenCount={children.length}
                leadPassenger={leadPassenger}
            />

            {specificLocationName && specificLocationAddress && (
                <SpecificLocationBox
                    specificLocationName={specificLocationName}
                    specificLocationAddress={specificLocationAddress}
                />
            )}

            <TransferFlightInfoBox
                simplified={false}
                withBorder
                arrivalTransfer={arrivalTransfer}
                arrivalFlightDate={arrivalFlightDate}
                departureTransfer={departureTransfer}
                departureFlightDate={departureFlightDate}
                arrivalDetails={arrivalDetails}
                departureDetails={departureDetails}
            />

            {contactInfo && (
                <ContactPersonBox
                    contactInfo={contactInfo}
                />
            )}

            {customInvoice && invoiceRecipient && (
                <BillingDetailsBox
                    customInvoice={customInvoice}
                    invoiceRecipient={invoiceRecipient}
                />
            )}

            <PickupTimeSMSBox
                smsOrdered={smsOrdered}
                smsReminderNumber={smsReminderNumber}
                smsLocale={foundMessageLanguage && foundMessageLanguage.name}
                bookingCancelled={false}
                departureTransfer={departureTransfer}
            />

            <MemoBox
                memo={memo}
            />

            <div
                style={{
                    marginTop: 30,
                    marginBottom: 30
                }}
                className="h-divider"
            />

            <Pricing
                greyLabels
                displaySalePrice={displaySalePrice}
                salePrice={salePrice}
                smsFee={paymentOptions?.smsFee}
                displaySmsFee={paymentOptions?.displaySmsFee}
                discount={discount}
                voucher={voucherSum?.used}
                paymentPending={paymentPending}
            />
        </div>
    );
}