import {
    BookingListResultPropTypes,
    BookingSearchCriteriaPropTypes,
    BookingSearchOptionsPropTypes,
    ClientRequiredCheckInInformationPropTypes,
    ColumnsRequest,
    HotelFlightDetailsPropTypes,
    OverPaymentOptionsResponsePropTypes,
    OverpaymentRequest,
    WebHotelBookingDetails,
    WebMultiBookingDetails,
    WebTransferBookingDetails
} from "proptypes/PropTypeObjects";
import {removeTimezoneInformationFromDate} from "utils/dateUtils";
import formatToLTDate from "utils/internationalization/formatToLTDate"
import {ConstrainedAxiosRequestConfig} from "./axios/axiosController";
import Currency from "@hotelston_web_frontend_parent/src/constants/currency";

export function getBookingDetails(paymentOrderId: number, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<WebMultiBookingDetails> {
    return {
        method: "get",
        url: "/app/booking/details",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            paymentOrderId,
            locale,
            currency
        },
        maxNumRequests: 1
    };
}
export function getBooking(id: number, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<WebTransferBookingDetails | WebHotelBookingDetails> {
    return {
        method: "get",
        url: `/app/booking/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            //id,
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function searchBookings(formData: BookingSearchCriteriaPropTypes, pageSize: number, pageNr: number, locale: string, currency: Currency): ConstrainedAxiosRequestConfig<BookingListResultPropTypes, BookingSearchCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/booking/search",
        data: {
            ...formData,
            createDateFrom: removeTimezoneInformationFromDate(formData.createDateFrom),
            createDateTo: removeTimezoneInformationFromDate(formData.createDateTo),
            serviceStartDateFrom: removeTimezoneInformationFromDate(formData.serviceStartDateFrom),
            serviceStartDateTo: removeTimezoneInformationFromDate(formData.serviceStartDateTo),
            serviceEndDateFrom: removeTimezoneInformationFromDate(formData.serviceEndDateFrom),
            serviceEndDateTo: removeTimezoneInformationFromDate(formData.serviceEndDateTo),
            cxlDateFrom: removeTimezoneInformationFromDate(formData.cxlDateFrom),
            cxlDateTo: removeTimezoneInformationFromDate(formData.cxlDateTo),
            invoiceDateFrom: removeTimezoneInformationFromDate(formData.invoiceDateFrom),
            invoiceDateTo: removeTimezoneInformationFromDate(formData.invoiceDateTo),
            paymentDeadlineFrom: removeTimezoneInformationFromDate(formData.paymentDeadlineFrom),
            paymentDeadlineTo: removeTimezoneInformationFromDate(formData.paymentDeadlineTo)
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            pageNr,
            pageSize,
            locale,
            currency
        },
        maxNumRequests: 1
    };
}

export function getSearchOptions(locale: string): ConstrainedAxiosRequestConfig<BookingSearchOptionsPropTypes> {
    return {
        method: "get",
        url: "/app/booking/searchOptions",
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        params: {
            locale
        },
        maxNumRequests: 1
    };
}

export function modifyColumns(columnsRequest: ColumnsRequest): ConstrainedAxiosRequestConfig<boolean, ColumnsRequest> {
    return {
        method: "post",
        url: "/app/booking/column",
        data: columnsRequest,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function cancelBooking(id: number): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "delete",
        url: `/app/booking/${id}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function getOverpaymentOptions(id: number): ConstrainedAxiosRequestConfig<OverPaymentOptionsResponsePropTypes> {
    return {
        method: "get",
        url: `/app/booking/${id}/overPayment`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function useOverpayment(id: number, overPaymentRequest: OverpaymentRequest): ConstrainedAxiosRequestConfig<boolean, OverpaymentRequest> {
    return {
        method: "post",
        url: `/app/booking/${id}/overPayment`,
        data: overPaymentRequest,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function resendVoucher(id: number): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "get",
        url: `/app/booking/${id}/voucher/resend`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function getBookingsExcel(formData: BookingSearchCriteriaPropTypes, currency: Currency, locale: string): ConstrainedAxiosRequestConfig<Blob, BookingSearchCriteriaPropTypes> {
    return {
        method: "post",
        url: "/app/booking/xls",
        data: formData,
        responseType: "blob",
        params: {
            locale,
            currency
        },
        timeout: 240000,
        maxNumRequests: 1
    };
}

export function setBookingMemo(id: number, memo: string): ConstrainedAxiosRequestConfig<boolean, string> {
    return {
        method: "put",
        url: `/app/booking/${id}/memo`,
        data: memo,
        headers: {
            "Content-Type": "text/plain",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function updateCheckInRemarks(id: number, formData: ClientRequiredCheckInInformationPropTypes): ConstrainedAxiosRequestConfig<boolean, ClientRequiredCheckInInformationPropTypes> {
    return {
        method: "put",
        url: `/app/booking/${id}/clientRequiredCheckinInformation`,
        data: {
            ...formData,
            checkInTime: formatToLTDate(formData.checkInTime)
        },
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function updateHotelTransferDetails(id: number, formData: HotelFlightDetailsPropTypes): ConstrainedAxiosRequestConfig<boolean, HotelFlightDetailsPropTypes> {
    return {
        method: "put",
        url: `/app/booking/${id}/flightDetails`,
        data: formData,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function updateBookingRetailPrice(id: number, newRetailPrice: number): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "put",
        url: `/app/booking/${id}/retailPrice?amount=${newRetailPrice}`,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}

export function orderSMSNotification(id: number, order: { phone: string, locale: string }): ConstrainedAxiosRequestConfig<boolean> {
    return {
        method: "POST",
        url: `/app/booking/${id}/sms`,
        data: order,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json"
        },
        maxNumRequests: 1
    };
}