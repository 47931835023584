import React, {ReactElement, useCallback, useEffect, useState} from "react"
import isEqual from "lodash/isEqual";
import BillingDetailsCheck from "components/utils/BillingDetailsCheck";
import FailureModal from "components/common/ConfirmationModal/FailureModal";
import i18n from "i18n";
import Spinner from "../../../components/base/Loaders/Spinner";
import MainContainer from "../../../components/base/MainContainer";
import BookingForm from "./BookingForm";
import BookingSummary from "./BookingSummary";
import scrollToFormError from "../../../utils/scrollToFormError";
import {Navigate} from "react-router-dom";
import router from "../../router";
import {useAppDispatch, useAppSelector} from "redux/hooks"
import {
    clearValidationData,
    handleContactInfo,
    handleInputChange,
    handleLeadPassenger,
    handleMemo,
    handleTransferBookingInfoInput,
    toggleSmsOrdered,
    updateBookingOptions,
    validateBookingStep
} from "redux/actions/transferBooking.actions"
import {useCurrency, useLocale} from "components/utils/withLocalesAndCurrencies"
import {handleInvoiceRecipient, toggleBillingDetails} from "redux/actions/payment.actions"

const BookingContainer = (): ReactElement => {
    const dispatch = useAppDispatch();

    const locale = useLocale();
    const currency = useCurrency();

    const [fillLater, setFillLater] = useState<boolean>(false);

    const route = useAppSelector((state) => state.transferBooking.data?.route);

    const bookingOptions = useAppSelector((state) => state.payment.bookingOptions);

    const loadedData = useAppSelector((state) => state.transferBooking.loadedData);
    const bookingInfo = useAppSelector((state) => state.transferBooking.data?.bookingInfo);
    const bookingOptionsParams = useAppSelector((state) => state.transferBooking.bookingOptionsParams);
    const transferBookingInfo = useAppSelector((state) => state.transferBooking.data?.transferBookingInfo);
    const transferAvailabilityCriteria = useAppSelector((state) => state.transferBooking.data?.transferAvailabilityCriteria);
    const currentOrigin = useAppSelector((state) => state.transferSearch.currentOrigin);
    const currentDestination = useAppSelector((state) => state.transferSearch.currentDestination);
    const errors = useAppSelector((state) => state.transferBooking.error);
    const requestingOptions = useAppSelector((state) => state.payment.loading);
    const requestingValidation = useAppSelector((state) => state.transferBooking.requestingValidation);
    const invoiceRecipient = useAppSelector((state) => state.payment.invoiceRecipient);

    const bookingsCount = useAppSelector((state) => state.auth.userData?.bookings || 0);

    const handleValidationAndSubmit = useCallback((e?: React.FormEvent<HTMLFormElement>) => {
        if (!e) {
            return;
        }

        const form = e.target as HTMLFormElement;
        e.preventDefault();
        const valid = form.checkValidity();

        if (!valid) {
            scrollToFormError(form);
        }

        if (!loadedData) {
            return;
        }

        if (errors) {
            dispatch(clearValidationData());
        }

        if (valid) {
            dispatch(validateBookingStep(1));
        }
    }, [dispatch, errors, loadedData]);

    useEffect(() => {
        if (
            !requestingOptions &&
            bookingOptionsParams &&
            !isEqual({
                locale,
                currency
            }, bookingOptionsParams)
        ) {
            dispatch(updateBookingOptions());
        }
    }, [bookingOptionsParams, currency, dispatch, locale, requestingOptions]);

    const handleCustomInvoiceRecipient = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            dispatch(handleInvoiceRecipient({
                ...invoiceRecipient,
                [e.target.name]: e.target.value,
            }));
        },
        [dispatch, invoiceRecipient]
    );


    return route === "NO_AVAILABILITY" ? (
        <Navigate to="/transfers/booking/changed"/>
    ) : (
        <MainContainer
            sidebar={
                bookingInfo && bookingOptions && transferBookingInfo && transferAvailabilityCriteria && currentOrigin && currentDestination && (
                    <BookingSummary
                        currentDestination={currentDestination}
                        currentOrigin={currentOrigin}
                        bookingOptions={bookingOptions}
                        transferAvailabilityCriteria={transferAvailabilityCriteria}
                    />
                )
            }
        >
            {requestingOptions || requestingValidation ? (
                <Spinner
                    size="50px"
                    style={{
                        width: "100%",
                        height: "calc( 100vh - 60px )"
                    }}
                />
            ) : (
                <div>
                    {
                        bookingOptions?.showBusinessCredentials &&
                        (
                            <BillingDetailsCheck
                                onConfirm={() => dispatch(toggleBillingDetails(false))}
                                onDecline={() => {
                                    if (bookingsCount > 5) {
                                        router.navigate(-1);
                                    } else {
                                        setFillLater(true);
                                    }
                                }}
                                fillLater={fillLater}
                                afterBook
                            />
                        )
                    }
                    {
                        errors && errors.includes("err_book_mustFillBusinessCredentials") &&
                        (
                            <FailureModal
                                failureMessage={i18n.t("t_b_bc_err_book_mustFillBusinessCredentials")}
                                onClose={() => router.navigate(-1)}
                            />
                        )
                    }

                    {bookingInfo && bookingOptions && transferBookingInfo && transferAvailabilityCriteria && (
                        <BookingForm
                            handleInputChange={(input, val) => dispatch(handleInputChange(input, val))}
                            handleMemo={(e) => dispatch(handleMemo(e))}
                            handleContactInfo={(e) => dispatch(handleContactInfo(e))}
                            toggleSmsOrdered={(e) => dispatch(toggleSmsOrdered())}
                            handleTransferBookingInfoInput={(e) => dispatch(handleTransferBookingInfoInput(e))}
                            handleLeadPassenger={(e) => dispatch(handleLeadPassenger(e))}
                            handleValidationAndSubmit={handleValidationAndSubmit}
                            handleCustomInvoiceRecipient = {handleCustomInvoiceRecipient}
                        />
                    )}
                </div>
            )}
        </MainContainer>
    );
}

export default BookingContainer;
