import {
    Reducer, useCallback, useEffect, useMemo, useReducer
} from "react";
import * as subagentsAPI from "api/subagentsAPI";
import {useTranslation} from "react-i18next";
import useIsMounted from "@hotelston_web_frontend_components/hooks/detection/useIsMounted";
import {
    CompanyDetails,
    CompanyListItem,
    CompanyListResponse,
    ItemModificationResponse,
    RegisterCompanyRequest
} from "../../proptypes/PropTypeObjects";
import {useAppSelector} from "../../redux/hooks";
import {globalAxiosController} from "../../api/axios/axiosInstance";

type SubagentDataProps = {
    companies?: CompanyListItem[],
    requestingSubagents: boolean,
    sort?: { key: string, order: string },
    error?: string
};

const initialState = {
    companies: undefined,
    requestingSubagents: true,
    pageNr: 1,
    sort: undefined,
    error: undefined
} as SubagentDataProps;

type SubagentActionProps =
    { type: "REQ_SUBAGENTS" }
    | { type: "REQ_SUBAGENTS_SUCCESS", companies: CompanyListItem[] }
    | { type: "REQ_SUBAGENTS_FAILURE", error: string }
    | { type: "UPDATE_SUBAGENT", subagent: CompanyListItem }
    | { type: "CREATE_SUBAGENT", subagent: CompanyListItem }
    | { type: "CHANGE_SORT", sort: { key: string, order: string } }
    ;
const reducer = (state: SubagentDataProps, action: SubagentActionProps): SubagentDataProps => {
    switch (action.type) {
        case "REQ_SUBAGENTS":
            return {
                ...state,
                error: undefined,
                requestingSubagents: true
            };
        case "REQ_SUBAGENTS_SUCCESS":
            return {
                ...state,
                requestingSubagents: false,
                companies: action.companies
            };
        case "REQ_SUBAGENTS_FAILURE":
            return {
                ...state,
                requestingSubagents: false,
                error: action.error
            };
        case "UPDATE_SUBAGENT":
            return {
                ...state,
                companies: state.companies?.map((sa: CompanyListItem) => (sa.id === action.subagent.id
                    ? {
                        ...sa,
                        ...action.subagent
                    }
                    : sa))
            };
        case "CREATE_SUBAGENT":
            return {
                ...state,
                companies: [action.subagent, ...(state.companies || [])]
            };
        case "CHANGE_SORT":
            return {
                ...state,
                sort: action.sort
            };
        default:
            return state;
    }
};
type UseSubagentsDataProps = {
    subagentsSelected: CompanyListItem[];
    requestingSubagents: boolean;
    sort?: { key: string, order: string };
    getSubagents: () => void;
    addSubagent: (subagent: CompanyDetails) => void;
    updateSubagent: (subagent: CompanyListItem) => void
    handlePageNr: (pageNr: number) => void;
    handlePageSize: (pageSize: number) => void;
    handleSort: any;
    error: any;
};

export default function useSubagentsData(): UseSubagentsDataProps {
    const [state, dispatch] = useReducer<Reducer<SubagentDataProps, any>>(reducer, initialState);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const {
        companies,
        requestingSubagents,
        sort,
        error
    } = state;

    const locale = useAppSelector((state) => state.locale.currentLocale);

    const getSubagents = useCallback(() => {
        dispatch({type: "REQ_SUBAGENTS"});
        globalAxiosController.addRequest(subagentsAPI.getSubagents(locale))
            .then((data: CompanyListResponse) => {
                if (isMounted) {
                    if (data) {
                        dispatch({
                            type: "REQ_SUBAGENTS_SUCCESS",
                            companies: data.companies
                        });
                    } else {
                        dispatch({
                            type: "REQ_SUBAGENTS_FAILURE",
                            error: t("util_usd_something_went_wrong")
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "REQ_SUBAGENTS_FAILURE",
                        error: error || t("util_usd_something_went_wrong")
                    });
                }
            });
    }, [locale, dispatch, t, isMounted]);

    const addSubagent = useCallback((subagent: any) => dispatch({type: "CREATE_SUBAGENT", subagent}), []);
    const updateSubagent = useCallback((subagent: any) => dispatch({type: "UPDATE_SUBAGENT", subagent}), []);
    const handlePageNr = useCallback((pageNr: number) => dispatch({type: "CHANGE_PAGE_NR", pageNr}), []);
    const handlePageSize = useCallback((pageSize: number) => dispatch({type: "CHANGE_PAGE_SIZE", pageSize}), []);
    const handleSort = useCallback((sort: any) => dispatch({type: "CHANGE_SORT", sort}), []);

    const subagentsSelected = useMemo(() => {
        if (!companies) return [];
        if (sort) {
            return companies
                .sort((aObj: any, bObj: any) => {
                    if (sort.key === "markup" || sort.key === "bookings") {
                        return sort.order === "DESC"
                            ? bObj[sort.key] - aObj[sort.key]
                            : aObj[sort.key] - bObj[sort.key];
                    }
                    return sort.order === "DESC"
                        ? bObj[sort.key].localeCompare(aObj[sort.key])
                        : aObj[sort.key].localeCompare(bObj[sort.key]);
                });
        }
        return companies;
    }, [companies, sort]);

    useEffect(() => {
        getSubagents();
    }, [getSubagents, locale]);

    return {
        subagentsSelected,
        requestingSubagents,
        sort,
        getSubagents,
        addSubagent,
        updateSubagent,
        handlePageNr,
        handlePageSize,
        handleSort,
        error
    } as UseSubagentsDataProps;
}

type ActionStateProps = {
    requesting: boolean,
    errors?: string[];
};

const actionState = {
    requesting: false,
    errors: undefined
} as ActionStateProps;

type ActionProps =
    { type: "REQUEST" }
    | { type: "CLEAR" }
    | { type: "SUCCESS" }
    | { type: "FAILURE", errors: string[] }
    ;

const actionReducer = (state: ActionStateProps, action: ActionProps): ActionStateProps => {
    switch (action.type) {
        case "CLEAR":
            return {
                ...actionState
            };
        case "REQUEST":
            return {
                ...state,
                errors: undefined,
                requesting: true
            };
        case "SUCCESS":
            return {
                ...state,
                requesting: false
            };
        case "FAILURE":
            return {
                ...state,
                requesting: false,
                errors: action.errors
            };
        default:
            return state;
    }
};

type UseUpdateSubagentAction = ActionStateProps & {
    updateSubagent: (subagent: CompanyListItem) => void;
    clearState: () => void;
};

export function useUpdateSubagentAction(onSuccess: (subagent: CompanyListItem) => void = () => {
}): UseUpdateSubagentAction {
    const [state, dispatch] = useReducer(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const updateSubagent = useCallback((subagent: CompanyListItem) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(subagentsAPI.updateSubagentDetails(subagent, locale))
            .then((data: ItemModificationResponse) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess(subagent);
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_usd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                dispatch({
                    type: "FAILURE",
                    errors: error ? [error as string] : [t("util_usd_something_went_wrong")]
                });
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        updateSubagent,
        clearState
    };
}

type UseCreateSubagentAction = ActionStateProps & {
    createSubagent: (subagent: RegisterCompanyRequest) => void;
    clearState: () => void;
};

export function useCreateSubagentAction(onSuccess: (subagent: CompanyDetails) => void = () => {
}): UseCreateSubagentAction {
    const [state, dispatch] = useReducer<Reducer<ActionStateProps, any>>(actionReducer, actionState);

    const locale = useAppSelector((state) => state.locale.currentLocale);
    const {t} = useTranslation();
    const isMounted = useIsMounted();

    const clearState = useCallback(() => {
        dispatch({type: "CLEAR"});
    }, []);

    const createSubagent = useCallback((subagent: RegisterCompanyRequest) => {
        dispatch({type: "REQUEST"});
        globalAxiosController.addRequest(subagentsAPI.createSubagent(subagent, locale))
            .then((data: ItemModificationResponse) => {
                if (isMounted) {
                    if (data && data.success) {
                        onSuccess({
                            ...subagent.companyDetails
                            //id: data.id
                        });
                        dispatch({type: "SUCCESS"});
                    } else {
                        dispatch({
                            type: "FAILURE",
                            errors: (data && data.errors) || [
                                t("util_usd_something_went_wrong")
                            ]
                        });
                    }
                }
            })
            .catch((error: unknown) => {
                if (isMounted) {
                    dispatch({
                        type: "FAILURE",
                        errors: error ? [error as string] : [t("util_usd_something_went_wrong")]
                    });
                }
            });
    }, [locale, onSuccess, t, isMounted]);
    return {
        ...state,
        createSubagent,
        clearState
    };
}
